import { gql, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { getGraphCMSStage } from '../../../util/api';
import GBIButton from '../../../util/buttons/components/GBIButton';
import { captureException } from '../../../util/logger';
import './featured-recipe.scss';
import Tag from './Tag';

const IMAGE_DIMS = 475;

const FEATURED_RECIPE = gql`
query {
  recipes(
    first: 1,
    stage: ${getGraphCMSStage()},
    where: { featuredRecipe: true }
  ) {
   slug
    title
    servingCount
    tags
    cut
    intro
    images(first: 1) {
      url(transformation: {
        image: { resize: {
          width: ${IMAGE_DIMS},
          height: ${IMAGE_DIMS}
          fit: crop
        }}
      })
    }
  }
}
`;

const FeaturedRecipe = () => {
  const [ imageStyle, setImageStyle ] = useState({
    opacity: 0
  });
  const handleImageLoad = () => {
    setImageStyle({
      opacity: 1
    });
  };
  const { loading, error, data } = useQuery(FEATURED_RECIPE);
  if (loading) {
    return null;
  } if (error) {
    captureException(`failed to load featured recipe: ${error}`);
    return null;
  }
  try {
    const recipe = data.recipes[0];
    if (typeof recipe === 'undefined') {
      captureException('no featured recipe returned');
      return null;
    }
    let tags = [];
    tags = tags.concat(
      recipe.cut.map(
        cut => <Tag key={ `cut_${cut}`} tag={ cut } filterComponent='cut' />
      )
    );
    tags = tags.concat(
      recipe.tags.map(
        tag => <Tag key={ `tag_${tag}` } tag={ tag } filterComponent='type' />
      )
    );
    return (
      <article className="featured-recipe">
        <div className="featured-recipe-image-wrapper">
          <img style={ imageStyle }
            className="featured-recipe-image"
            data-cy="featured-recipe-image"
            onLoad={ handleImageLoad }
            src={ recipe.images[0].url } />
        </div>
        <div className="details">
          <h3 className="featured-recipe-label" data-cy="featured-recipe-label">
            featured recipe
          </h3>
          <h2 className="featured-recipe-title">{ recipe.title }</h2>
          <div className="featured-recipe-tags">{ tags }</div>
          <h6 className="featured-recipe-servings">
            { `Serves ${recipe.servingCount}` }
          </h6>
          <p className="featured-intro">{ recipe.intro }</p>
          <Link to={ `/recipes/${recipe.slug}` } >
            <GBIButton label="View the recipe" testId="featured-recipe-button"/>
          </Link>
        </div>
      </article>
    );
  } catch (error) {
    captureException(`failed to display featured recipe: ${error}`);
    return null;
  }
};

export default FeaturedRecipe;
