import React from 'react';
import PropTypes from 'prop-types';
import './author.scss';

const Author = ({ author }) => {
  if (!author) return null;
  return (
    <a href={ author.url } target="_blank" rel="noopener noreferrer">
      <div className="author-attribution">
        <figure className="author-image-wrapper">
          <img src={ author.image.url } alt={ author.name }/>
        </figure>
        <p className="brought-to-you">brought to you by</p>
        <p className="name">{ author.name }</p>
      </div>
    </a>
  );
};

Author.propTypes = {
  author: PropTypes.shape({
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    image: PropTypes.shape({
      url: PropTypes.string.isRequired
    })
  })
};

export default Author;
