import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './filter-tag.scss';

const Tag = ({ tag, filterComponent }) => {
  const tagComponent = encodeURIComponent(tag);
  const label = tag.replaceAll('_', ' ');
  return (
    <Link to={ `/recipes/${filterComponent}/${tagComponent}` } >
      <div className="recipe-filter-tag" data-cy="recipe-filter-tag">
        { label }
      </div>
    </Link>
  );
};

Tag.propTypes = {
  tag: PropTypes.string.isRequired,
  filterComponent: PropTypes.string.isRequired
};

export default Tag;
