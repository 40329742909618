import { gql } from '@apollo/client';
import { getGraphCMSStage } from '../../util/api';

const IMAGE_HEIGHT = 377;
const IMAGE_WIDTH = IMAGE_HEIGHT;

export const RECIPE_MENU_ITEMS = gql`
{
  recipes(stage: ${getGraphCMSStage()}) {
    slug
    title
    cut
    tags
    servingCount
    images(first: 1) {
      url(
        transformation: {
          image: { resize: { 
            width: ${IMAGE_WIDTH},
            height: ${IMAGE_HEIGHT},
            fit: crop
          }}
        }
      )
    }
  }
}
`;

export const RECIPE_MENU_ITEMS_BY_TYPE = gql`
query Recipe($types: [String!]) {
  recipes(stage: ${getGraphCMSStage()}, where: {tags_contains_all: $types}) {
    slug
    title
    cut
    tags
    servingCount
    images(first: 1) {
      url(
        transformation: {
          image: { resize: { 
            width: ${IMAGE_WIDTH},
            height: ${IMAGE_HEIGHT},
            fit: crop
          }}
        }
      )
    }
  }
}
`;

export const RECIPE_MENU_ITEMS_BY_CUT = gql`
query Recipe($cuts: [Cut!]){
  recipes(stage: ${getGraphCMSStage()}, where: {cut_contains_all: $cuts}) {
    slug
    title
    cut
    tags
    servingCount
    images(first: 1) {
      url(
        transformation: {
          image: { resize: { 
            width: ${IMAGE_WIDTH},
            height: ${IMAGE_HEIGHT},
            fit: crop
          }}
        }
      )
    }
  }
}
`;

export const CUTS = gql`
{
  __type(name: "Cut") {
    enumValues {
      name
    }
  }
}
`;

export const CUTS_AND_TYPES = gql`
{
  recipes(stage: ${getGraphCMSStage()}) {
    cut
    tags
  }
}
`;
