import { gql, useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import LoadingIcon from '../../../util/icons/components/LoadingIcon';
import GBIButton from '../../../util/buttons/components/GBIButton';
import { Link, useParams } from 'react-router-dom';
import './recipe.scss';
import RecipeImages from './RecipeImages';
import { getGraphCMSStage } from '../../../util/api';
import ArrowIcon from '../../../util/icons/components/ArrowIcon';
import Author from './Author';
import Tag from './Tag';
import AnimalUsed from './AnimalUsed';
import NotFound from '../../../util/components/NotFound';

const IMAGE_DIMS = 475;
const THUMB_DIMS = 50;
const AUTHOR_IMAGE_DIMS = 60;

const RECIPE = gql`
  query Recipe($slug: String!) {
    recipe(where: { slug: $slug }, stage: ${getGraphCMSStage()}) {
      slug
      title
      cut
      tags
      servingCount
      intro
      animalID
      ingredients {
        html
      }
      methodSteps {
        html
      }
      images(first: 5) {
        url(
          transformation: {
            image: { resize: {
              width: ${IMAGE_DIMS},
              height: ${IMAGE_DIMS},
              fit: crop}
            }
          }
        )
        thumbnail: url(
          transformation: {
            image: { resize: {
              width: ${THUMB_DIMS},
              height: ${THUMB_DIMS},
              fit: crop}
            }
          }
        )
      }
      author {
        name
        url
        image {
          url(
            transformation: {
              image: { resize: {
                width: ${AUTHOR_IMAGE_DIMS},
                height: ${AUTHOR_IMAGE_DIMS},
                fit: crop
              }}
            }
          )
        }
      }
    }
  }
`;

const Recipe = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }, []);
  let { slug } = useParams();
  const { loading, error, data } = useQuery(RECIPE, { variables: { slug } });
  if (loading) {
    return <div className="recipe-loading"><LoadingIcon /></div>;
  } else if (error) {
    return <p className="error-loading">error loading content</p>;
  } if (data.recipe === null) {
    return <NotFound />;
  }
  let method = null;
  let ingredients = null;
  try {
    const html = { __html: data.recipe.methodSteps.html };
    method = (
      <div className="method" dangerouslySetInnerHTML={ html }></div>
    );
  } catch (error) { /* */ }
  try {
    const html = { __html: data.recipe.ingredients.html };
    ingredients = (
      <div className="ingredients"
        dangerouslySetInnerHTML={ html }></div>
    );
  } catch (error) { /* */ }
  let tags = [];
  tags = tags.concat(
    data.recipe.cut.map(
      cut => <Tag key={ `cut_${cut}`} tag={ cut } filterComponent='cut' />
    )
  );
  tags = tags.concat(
    data.recipe.tags.map(
      tag => <Tag key={ `tag_${tag}` } tag={ tag } filterComponent='type' />
    )
  );
  return (
    <div id="recipe-page">
      <div className='back-button'>
        <Link to='/recipes'>
          <ArrowIcon />
          Back to recipes
        </Link>
      </div>
      <div className="images-and-intro">
        <RecipeImages images={ data.recipe.images || [] } />
        <div className="intro">
          <h1 className="recipe-title" data-cy="recipe-title">
            { data.recipe.title }
          </h1>
          <div className="tags">
            { tags }
          </div>
          <div className="servings">
            { `Serves ${data.recipe.servingCount}`}
          </div>
          <p className="intro-text" data-cy="recipe-intro">
            { data.recipe.intro }
          </p>
          <Author author={ data.recipe.author } />
        </div>
      </div>
      <div className="animal-used-wrapper">
        <AnimalUsed animalId={ data.recipe.animalID } />
      </div>
      <div className="details">
        <div className="ingredients" data-cy="ingredients">
          <h3 className="ingredients-heading">Ingredients</h3>
          { ingredients }
        </div>
        <div className="method" data-cy="method">
          <h2 className="method-heading">Method</h2>
          { method }
        </div>
      </div>
      <div className="recipe-footer">
        <h3 className="caption">Good beef is good for you</h3>
        <Link to="/good-beef/goodbeef">
          <GBIButton className="goodbeef-link"
            testId="good-beef-link-button"
            onClick={ () => window.scrollTo({ top: 0, behavior: 'auto' })}
            label="Find out more..." />
        </Link>
      </div>
    </div>
  );
};

export default Recipe;
