import React, { useState, useEffect } from 'react';
import { getGradeFromScore } from '../../../admin/scoreDefinitions/util';
import CowAPI from '../../../cows/CowAPI';
import BeefBookQualityIndex from
  '../../../cows/list/components/BeefBookQualityIndex';
import GBIGradeShield from '../../../util/logos/GBIGradeShield';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './animal-used.scss';
import { calcScores } from '../../../cows/reducer';

const AnimalUsed = ({ animalId }) => {
  if (!animalId) return null;
  const [mountTime, setMountTime] = useState(null);
  const [animal, setAnimal] = useState(null);
  useEffect(() => {
    CowAPI.fetchById(animalId).then(res => {
      if (res.data) {
        const animal = { ...res.data };
        calcScores(animal);
        animal.grade = getGradeFromScore(animal.beefQualityIndex);
        setAnimal(animal);
        setMountTime(new Date());
      }
    }).catch(error => {
      console.log(`failed to fetch animal ${error.toString()}`);
    });
  }, []);
  if (!animal) return null;
  const grade = getGradeFromScore(animal.beefQualityIndex);
  // const animalURL = `/animal/${animal.id}`;
  const marbling = animal.IMF ? (
    <div className="marbling">
      <h5 className="marbling-title">
        Marbling
        <span className="scale">Scale</span>
      </h5>
      <h4 className="marbling-number">{ animal.IMF + '%' }</h4>
    </div>
  ) : null;
  return (
    <section className="animal-used-for-recipe">
      <h4 className="animal-used-header">
        For this recipe we used...
      </h4>
      <div className="components">
        <Link to={ `/animal/${animal.id}` } >
          <div className="grade-section">
            <div className="shield-block">
              <GBIGradeShield grade={ grade } />
            </div>
            <div className="name-block">
              <h5 className="breed">{ animal.breed }</h5>
              <h4 className="animal-name">{ animal.name }</h4>
              <h6 className="tag">{ animal.tag }</h6>
            </div>
          </div>
        </Link>
        <div className="scores">
          <Link to={ `/animal/${animal.id}` } >
            <BeefBookQualityIndex cow={ animal } mountTime={ mountTime } />
          </Link>
        </div>
        <div className="marbling-and-purchase">
          <Link to={ `/animal/${animal.id}` } >
            { marbling }
          </Link>
          <Link to="/for-consumers/where-to-buy" className="buy-link">
            <div className="where-to-buy-button"
              onClick={ window.scrollTo({ top: 0, behavior: 'auto' })}>
              Where to buy
            </div>
          </Link>
        </div>
      </div>
    </section>
  );
};

AnimalUsed.propTypes = {
  animalId: PropTypes.string
};

export default AnimalUsed;
