/* global performance, Image */
import React, { useState, useEffect } from 'react';
import './recipe-images.scss';
import PropTypes from 'prop-types';

const RecipeImages = ({ images }) => {
  const [ mainImageLoaded, setMainImageLoaded ] = useState(false);
  const [ mainImage, setMainImage ] = useState(images[0].url);
  const [ currentIndex, setCurrentIndex ] = useState(0);
  const [ backgroundImage, setBackgroundImage ] = useState(images[0].url);
  const [ transitioning, setTransitioning ] = useState();
  const [ cacheBustingCount, setCacheBustingCount ] = useState(0);
  const handleSelection = index => {
    if (performance && performance.now) {
      const now = performance.now();
      if ((now - transitioning) < 300) return;
      setTransitioning(performance.now());
    }
    setBackgroundImage(mainImage);
    const newSrc = images[index].url;
    setMainImage(newSrc);
    setCurrentIndex(index);
    setCacheBustingCount(cacheBustingCount + 1);
  };
  useEffect(() => {
    const img = new Image();
    img.onload = () => setMainImageLoaded(true);
    img.src = images[0].url;
  }, []);
  const thumbnails = images.slice(0, 5).map((image, index) => {
    const className = index === currentIndex ? 'thumbnail' : 'thumbnail fade';
    const style = {
      backgroundImage: `url(${image.thumbnail})`
    };
    return (
      <div className={ className }
        key={ image.thumbnail }
        style={ style }
        onClick={ () => handleSelection(index) } ></div>
    );
  });
  const foregroundImageStyle = {
    backgroundImage: `url(${mainImage})`,
    opacity: mainImageLoaded ? 1 : 0
  };
  const foregroundImage = (
    <div className="foreground-image"
      key={ mainImage + cacheBustingCount }
      style={ foregroundImageStyle } ></div>
  );
  const backgroundImageStyle = {
    backgroundImage: `url(${backgroundImage})`
  };
  const fadingImage = (
    <div className="background-image"
      key={ mainImage + cacheBustingCount }
      style={ backgroundImageStyle } ></div>
  );
  return (
    <div className="recipe-images">
      <div className="thumbnails">
        { thumbnails }
      </div>
      <div className="main-image">
        { foregroundImage }
        { fadingImage }
      </div>
    </div>
  );
};

RecipeImages.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      thumbnail: PropTypes.string
    })
  ).isRequired
};

export default RecipeImages;
