import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import DropDownSelect from '../../../util/form/components/DropDownSelect';
import history from '../../../goodBeefIndex/gbiHistory';
import './recipe-filters.scss';
import { CUTS_AND_TYPES } from '../queries';
import { captureException } from '../../../util/logger';

const RecipeFilters = ({ cut, type }) => {
  let cutSelector = (
    <div className="loading-filter-data">
      search by <span className="highlight">cut</span>
    </div>
  );
  let typeSelector = (
    <div className="loading-filter-data">
      search by <span className="highlight">filter</span>
    </div>
  );
  const { loading, error, data } = useQuery(CUTS_AND_TYPES);
  const handleCutSelection = selection => {
    if (selection === 'All') {
      history.push('/recipes');
    } else {
      history.push(`/recipes/cut/${selection}`);
    }
  };
  const handleTypeSelection = selection => {
    if (selection === 'All') {
      history.push('/recipes');
    } else {
      history.push(`/recipes/type/${selection}`);
    }
  };
  if (loading) {
    if (cut) {
      cutSelector = (
        <div className="loading-filter-data">
          showing <span className="highlight">
            { cut.replace('_', ' ') }
          </span>
        </div>
      );
    } else if (type) {
      typeSelector = (
        <div className="loading-filter-data">
          showing <span className="highlight">
            { cut.replace('_', ' ') }
          </span>
        </div>
      );
    }
  } else if (error) {
    captureException(`error loading recipe filters ${error.toString()}`);
  } else {
    try {
      const cuts = new Set();
      const types = new Set();
      data.recipes.forEach(recipe => {
        recipe.cut.forEach(cut => cuts.add(cut));
        recipe.tags.forEach(type => types.add(type));
      });
      const cutPlaceholder = cut ? (
        <div>showing <span className="highlight">
          { cut }
        </span></div>
      ) : (
        <div>search by <span className="highlight">cut</span></div>
      );
      const typePlaceholder = type ? (
        <div>showing <span className="highlight">
          { type }
        </span></div>
      ) : (
        <div>search by <span className="highlight">type</span></div>
      );
      const cutOptions = Array.from(cuts).map(cutItem => ({
        name: cutItem.replace('_', ''),
        value: cutItem,
        selected: cutItem === cut
      }));
      cutOptions.unshift({ name: 'All', value: 'All', selected: !cut });
      cutSelector = (
        <DropDownSelect options={ cutOptions }
          className="recipe-filter"
          testId="recipe-cut-filter"
          onChange={handleCutSelection}>
          { cutPlaceholder }
        </DropDownSelect>
      );
      const typeOptions = Array.from(types).map(typeItem => ({
        name: typeItem.replace('_', ''),
        value: typeItem,
        selected: typeItem === type
      }));
      typeOptions.unshift({ name: 'All', value: 'All', selected: !type });
      typeSelector = (
        <DropDownSelect options={ typeOptions }
          className="recipe-filter"
          onChange={handleTypeSelection}>
          { typePlaceholder }
        </DropDownSelect>
      );
    } catch (error) {
      captureException(`error displaying recipe filters ${error.toString()}`);
    }
  }
  return (
    <div className="recipe-filters">
      { cutSelector }
      { typeSelector }
    </div>
  );
};

RecipeFilters.propTypes = {
  cut: PropTypes.string,
  type: PropTypes.string
};

export default RecipeFilters;
