import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './dropdown-select.scss';
import DropDownArrowIcon from '../../icons/components/DropDownArrowIcon';

class DropDownSelect extends Component {
  constructor (props) {
    super(props);
    this.state = {
      open: false
    };
    this.toggleVisibility = this.toggleVisibility.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount () {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside (event) {
    if (this.wrapperRef && this.state.open === true &&
        !this.wrapperRef.contains(event.target)) {
      this.setState({ open: false });
    }
  }

  setWrapperRef (node) {
    this.wrapperRef = node;
  }

  hasOptionSelected () {
    let optionSelected = false;
    this.props.options.forEach(option => {
      if (option.selected === true) {
        optionSelected = true;
      }
    });
    return optionSelected;
  }

  getSelectedOptionText () {
    let selectedOptionText = '';
    this.props.options.forEach(option => {
      if (option.selected === true) {
        selectedOptionText = option.name;
      }
    });
    return selectedOptionText;
  }

  getMainText () {
    if (this.props.children) {
      return this.props.children;
    }
    if (this.hasOptionSelected()) return this.getSelectedOptionText();
    if (this.props.placeholder) return this.props.placeholder;
    return 'select';
  }

  renderOptions () {
    return this.props.options.map(option => {
      const handleClick = () => {
        this.props.onChange(option.value);
        this.setState({ open: false });
      };
      const className = option.selected ? 'option selected' : 'option';
      return (
        <li className={ className }
          key={ option.value }
          data-cy={ `${this.props.testId}-option-${option.value}`}
          onClick={ handleClick } >
          { option.name }
        </li>
      );
    });
  }

  toggleVisibility () {
    this.setState({ open: !this.state.open });
  }

  render () {
    const optionsClass = this.state.open
      ? 'dropdown-options visible' : 'dropdown-options hidden';
    let className = this.props.className ?
      this.props.className + ' bb-dropdown-select' :
      'bb-dropdown-select';
    if (this.state.open) className += ' open';
    return (
      <div className={ className } ref={this.setWrapperRef} >
        <div data-cy={ this.props.testId }
          onClick={ this.toggleVisibility }
          className='bb-dropdown-main'>
          { this.getMainText() }
        </div>
        <DropDownArrowIcon />
        <ul className={ optionsClass }>
          { this.renderOptions() }
        </ul>
      </div>
    );
  }
};

DropDownSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]).isRequired,
      selected: PropTypes.bool
    })
  ),
  placeholder: PropTypes.string,
  testId: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default DropDownSelect;
