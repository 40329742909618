import React from 'react';

/* eslint-disable max-len */
const GoodBeefFactorFigure = () => (
  <svg className="goodbeef-factor-figure" viewBox="0 0 238.806 238.806">
    <circle className="dotted-circle1" cx="80.5" cy="80" r="80.5" fill="none" strokeWidth="1" stroke="#a5a5a5" strokeDasharray="3,1"/>
    <circle className="dotted-circle2" cx="157.8" cy="80" r="80.5" fill="none" strokeWidth="1" stroke="#a5a5a5" strokeDasharray="3,1"/>
    <circle className="dotted-circle3" cx="119.1" cy="157.6" r="80.5" fill="none" strokeWidth="1" stroke="#a5a5a5" strokeDasharray="3,1"/>
    <circle className="thick-circle1" cx="80.5" cy="80" r="75.5" fill="none" strokeWidth="10" stroke="#fff"/>
    <circle className="thick-circle2" cx="157.8" cy="80" r="75.5" fill="none" strokeWidth="10" stroke="#fff"/>
    <circle className="thick-circle3" cx="119.1" cy="157.6" r="75.5" fill="none" strokeWidth="10" stroke="#fff"/>
    <path className="shield" d="M163.88,90.354a80.592,80.592,0,0,0-82.65,0,80.605,80.605,0,0,0,41.325,62.176A80.605,80.605,0,0,0,163.88,90.354Z" transform="translate(-3.152 -1.317)" fill="#c92f6d"/>
    <polygon className="tick" points="117.788 125.406 102.926 111.486 105.417 108.828 117.308 119.965 137.628 92.81 140.546 94.994 117.788 125.406" fill="#fff"/>
  </svg>
);

export default GoodBeefFactorFigure;
