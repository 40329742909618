import { useQuery } from '@apollo/client';
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import GBIButton from '../../../util/buttons/components/GBIButton';
import SectionLoading from '../../components/SectionLoading';
import {
  RECIPE_MENU_ITEMS,
  RECIPE_MENU_ITEMS_BY_CUT,
  RECIPE_MENU_ITEMS_BY_TYPE
} from '../queries';
import PropTypes from 'prop-types';
import './recipe-list.scss';
import RecipeFilters from './RecipeFilters';
import FeaturedRecipe from './FeaturedRecipe';
import Tag from './Tag';

const RecipeList = ({ match }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }, []);
  const { cut, type } = useParams();
  const [ loadedImages, setLoadedImages ] = useState({});
  let result = null;
  if (cut) {
    result = useQuery(RECIPE_MENU_ITEMS_BY_CUT, {
      variables: { cuts: [cut] }
    });
  } else if (type) {
    result = useQuery(RECIPE_MENU_ITEMS_BY_TYPE, {
      variables: { types: [type] }
    });
  } else {
    result = useQuery(RECIPE_MENU_ITEMS);
  }
  const { loading, error, data } = result;
  let content = null;
  if (loading) {
    content = (
      <div className="wrapper">
        <SectionLoading />
      </div>
    );
  } else if (error) {
    content = (
      <div className="wrapper">
        <p>error loading content</p>
      </div>
    );
  } else {
    content = data.recipes.map(recipe => {
      const src = recipe.images && recipe.images.length ?
        recipe.images[0].url : null;
      let tags = [];
      tags = tags.concat(
        recipe.cut.map(
          cut => <Tag key={ `cut_${cut}`} tag={ cut } filterComponent='cut' />
        )
      );
      tags = tags.concat(
        recipe.tags.map(
          tag => <Tag key={ `tag_${tag}` } tag={ tag } filterComponent='type' />
        )
      );
      tags.push(
        <div key="servings"
          className="servings">
          { `Serves ${recipe.servingCount}` }
        </div>
      );
      const handleImageLoad = () => {
        setLoadedImages({
          ...loadedImages, [ recipe.slug ]: true
        });
      };
      const imgStyle = {
        opacity: loadedImages[recipe.slug] ? 1 : 0
      };
      return (
        <div key={recipe.slug} className="recipe" data-cy="recipe-list-item">
          <Link className="img-wrapper" to={ `/recipes/${recipe.slug}` }>
            <img src={ src }
              loading="lazy"
              data-cy={ `image ${recipe.slug}`}
              style={ imgStyle }
              onLoad={ handleImageLoad }
              className="recipe-image"
              alt={ `delicious ${recipe.title}` } />
          </Link>
          <Link to={ `/recipes/${recipe.slug}` }>
            <h5 className="recipe-title"
              data-cy={ `recipe-list-item-title-${recipe.slug}`}>
              { recipe.title }
            </h5>
          </Link>
          <div className="tags">
            { tags }
          </div>
          <Link to={ `/recipes/${recipe.slug}` }>
            <GBIButton label="View the recipe"
              testId={ `recipe-link-${recipe.slug}` }/>
          </Link>
        </div>
      );
    });
  }
  const featuredRecipe = cut || type ? null : <FeaturedRecipe />;
  return (
    <div id="recipe-list" className="recipe-list">
      <h1 className="recipes-title">Recipes</h1>
      <RecipeFilters cut={ cut || '' } type={ type || '' }/>
      { featuredRecipe }
      <div className="recipes">
        { content }
      </div>
    </div>
  );
};

RecipeList.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string
  })
};

export default RecipeList;
