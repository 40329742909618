import React from 'react';
import { ApolloProvider } from '@apollo/client';
import apolloClient from '../../apolloClient';
import { PropTypes } from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import Recipe from './Recipe';
import RecipeList from './RecipeList';
import './recipes-section.scss';

const Provider = ({ match }) => {
  return (
    <ApolloProvider client={ apolloClient } >
      <div id="recipes-section">
        <Switch>
          <Route exact path={ `${match.url}`} render={
            props => <RecipeList { ...props } />
          } />
          <Route path={ `${match.url}/type/:type` } render={
            props => <RecipeList { ...props } />
          } />
          <Route path={ `${match.url}/cut/:cut` } render={
            props => <RecipeList { ...props } />
          } />
          <Route path={ `${match.url}/:slug` } render={
            props => <Recipe { ...props } />
          } />
        </Switch>
      </div>
    </ApolloProvider>
  );
};

Provider.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      slug: PropTypes.string
    }),
    url: PropTypes.string
  })
};

export default Provider;
