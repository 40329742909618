import React from 'react';
import LoadingIcon from '../../util/icons/components/LoadingIcon';
import './page-section-loading.scss';

const SectionLoading = () => (
  <div className="page-section-loading">
    <LoadingIcon />
  </div>
);

export default SectionLoading;
