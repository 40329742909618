//  https://codepen.io/matttherat/pen/EeMaEw

/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import './small-donut-chart.scss';
import './donut-chart-keyframes.scss';
import GoodBeefFactorFigure from '../../figures/GoodBeefFactorFigure';
import { factorElementDefinitions } from '../../../admin/scoreDefinitions/util';

const SmallDonutChart = ({ score, type, onClick, hover }) => {
  const percentage = Math.round(score / 4 * 10);
  const segmentStyle = {
    animation: `donut${percentage} ${score * 20 * 2}ms ease-out`
  };
  const segmentDasharray = `${percentage} ${100 - percentage}`;
  const strokeWidth = 5;
  let rank = 'D';
  if (score >= 100 / 3) {
    rank = 'A';
  } else if (score > 80 / 3) {
    rank = 'B';
  } else if (score > 60 / 3) {
    rank = 'C';
  }
  const className = 'small-donut-chart ' + rank + '-rank';
  const label = type + ' quality';
  return (
    <div onClick={ onClick } className={ className }>
      <svg viewBox='0 0 40 40' className='donut'>
        <circle className='donut-hole' cx='20' cy='20' r='16.41549430918954' fill='#fff'></circle>
        <circle className='donut-ring' cx='20' cy='20' r='16.41549430918954' fill='transparent' strokeWidth={strokeWidth}></circle>
        <circle style={segmentStyle} className='donut-segment donut-segment-2' cx='20' cy='20' r='16.41549430918954' fill='transparent' strokeWidth={strokeWidth} strokeDasharray={segmentDasharray} strokeDashoffset='25'></circle>
        <g className='donut-text donut-text-1'>
          <text y='58%' transform='translate(0, 2)'>
            <tspan x='50%' textAnchor='middle' className='donut-percent'>
              { score }
            </tspan>
          </text>
        </g>
      </svg>
      <div className={'tooltip ' + type }>
        <GoodBeefFactorFigure label={ label } />
        <div className="triangle"></div>
        <div>
          <h5 className="tooltip-title">{ type } quality (/40)</h5>
          {factorElementDefinitions[type]}
        </div>
      </div>
      {/* { hover === true ? (
        <div className="tooltip">
          <div className="triangle"></div>
          <h5 className="tooltip-title">{ type } quality</h5>
          {toolTips[type]}
        </div>
      ) : null } */}
      <h6 className={ 'label ' + type }>{label}</h6>
    </div>
  );
};

SmallDonutChart.propTypes = {
  score: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  hover: PropTypes.bool
};

export default SmallDonutChart;
