/* eslint-disable max-len */
import React from 'react';

const DropDownArrowIcon = () => (
  <svg className="dropdown-arrow" viewBox="0 0 98.02 54.59">
    <polygon points="0 5.58 5.58 0 49.01 43.42 92.43 0 98.02 5.58 49.01 54.59 0 5.58"/>
  </svg>
);

export default DropDownArrowIcon;
